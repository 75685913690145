// test chain
// export const MINER_TOKEN_ADDRESS = '0xA6B4F39C237a2A380b9CFBe96d9Ac071d747351E';
// export const JKT_TOKEN_ADDRESS = '0x8744d993162B543251879260B7029BfEBf6156Be';
// export const BIT_TOKEN_ADDRESS = '0x55FB5d3e107aAf137f608A76C1E13bED91880aD7';
// export const BNB_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000002';
// export const USDT_TOKEN_ADDRESS = '0x8994077E9Fc24d5140d04eC879362ba54Ee8289b';
// export const USDT_LP_TOKEN_ADDRESS = '0x8744d993162B543251879260B7029BfEBf6156Be';
//
// export const BSC_CHAIN_ID = '0x61';
// export const JKT_DECIMAL = 18;

// prod chain
export const MINER_TOKEN_ADDRESS = '0x4eB3d4E134E322396d06E903A154FE7CF1164157';
export const JKT_TOKEN_ADDRESS = '0x8E8123e286437B281cAa8abbF3A7f0CDf72b0aE6';
export const BIT_TOKEN_ADDRESS = '0xc864019047B864B6ab609a968ae2725DFaee808A';
export const BNB_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000002';
export const USDT_TOKEN_ADDRESS = '0x55d398326f99059fF775485246999027B3197955';
export const USDT_LP_TOKEN_ADDRESS = '0xaa4d9ae254dcc631193cc574cacab27baa85c27b';
export const BTC_TOKEN_ADDRESS = '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c';
export const ETH_TOKEN_ADDRESS = '0x2170ed0880ac9a755fd29b2688956bd959f933f8';

export const BSC_CHAIN_ID = '0x38';
export const JKT_DECIMAL = 18;
