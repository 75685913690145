
  import { ref, defineComponent, watchEffect } from 'vue';
  import homeActive from '../assets/images/tabbar/home-active.png';
  import homeInactive from '../assets/images/tabbar/home-inactive.png';
  import gamefiActive from '../assets/images/tabbar/gamefi-active.png';
  import gamefiInactive from '../assets/images/tabbar/gamefi-inactive.png';
  import defiIActive from '../assets/images/tabbar/defi-active.png';
  import defiInactive from '../assets/images/tabbar/defi-inactive.png';
  import accountActive from '../assets/images/tabbar/account-active.png';
  import accountInactive from '../assets/images/tabbar/account-inactive.png';

  export default defineComponent({
    name: 'ComTabBar',
    props: { defaultPath: String },
    setup(props) {
      const active = ref(0);

      const tabBarList = ref();
      tabBarList.value = [
        {
          title: 'Home',
          path: '/home',
          active: homeActive,
          inactive: homeInactive,
        },
        {
          title: 'GameFi',
          path: '/gameFi',
          active: gamefiActive,
          inactive: gamefiInactive,
        },
        {
          title: 'DeFi',
          path: '/deFi',
          active: defiIActive,
          inactive: defiInactive,
        },
        {
          title: 'Account',
          path: '/account',
          active: accountActive,
          inactive: accountInactive,
        },
      ];

      watchEffect(() => {
        if (props.defaultPath) {
          const secondPos = props.defaultPath.substr(1).indexOf('/');
          if (secondPos !== -1) {
            const activeRootPath = props.defaultPath.substr(0, secondPos + 1);
            active.value = tabBarList.value.findIndex((val: any) => val.path === activeRootPath);
          } else {
            active.value = tabBarList.value.findIndex((val: any) => val.path === props.defaultPath);
          }
        }
      });

      return {
        tabBarList,
        active,
      };
    },
  });
