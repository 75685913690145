
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import { vipLevel } from '@/const/vipLevel';

  export default defineComponent({
    name: 'comHeader',
    props: {
      title: String,
      'vip_level': Object,
      adress: String,
    },
    setup() {
      const router = useRouter();

      function handleGoBack() {
        router.go(-1);
      }

      return { handleGoBack, vipLevel };
    },
  });
