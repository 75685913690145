<template>
  <div style=''>
    <div class="layout-fixed-width">
      <com-header :title='state.title' :vip_level='vipInfo' :adress='userAddress'></com-header>
      <div style='padding-top: 50px;padding-bottom: 80px;' class="content-section">
        <router-view @postRefreshUserInfo="refreshUserInfo"/>
      </div>
      <com-tab-bar :default-path='state.fullPath'></com-tab-bar>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    reactive,
    onMounted,
    ref,
  } from 'vue';
  import {
    onBeforeRouteLeave,
    useRoute,
    onBeforeRouteUpdate,
    useRouter,
  } from 'vue-router';
  import ComHeader from './ComHeader.vue';
  import ComTabBar from '@/components/ComTabBar.vue';
  import {
    vipLevel,
  } from '@/const/vipLevel';
  import Web3Provider from '@/utils/Web3Provider';

  export default defineComponent({
    name: 'layout',
    components: {
      ComHeader,
      ComTabBar,
    },
    emits: ['postRefreshUserInfo'],
    setup() {
      const router = useRouter();
      const route = useRoute();
      const state = reactive({
        title: '',
        fullPath: '',
      });
      const {
        meta,
      } = route;
      state.title = meta.title;
      state.fullPath = route.fullPath;

      const vipInfo = reactive({
        level: 'N/A',
        icon: 'not_vip',
      });

      const userAddress = ref();
      userAddress.value = '';

      onBeforeRouteLeave((to, from) => {
        state.title = to.meta.title;
        state.fullPath = to.fullPath;
      });

      onBeforeRouteUpdate((to, from) => {
        state.title = to.meta.title;
        state.fullPath = to.fullPath;
      });

      const handleGlobalListener = () => {
        const eventManager = Web3Provider.getInstance().getEventManager();

        eventManager.on('accountsChanged', () => {
          localStorage.removeItem('signature');
          localStorage.removeItem('parentAddr');
          router.push({
            path: '/splashScreen',
          });
        });

        eventManager.on('NotBscChain', () => {
          localStorage.removeItem('parentAddr');
          localStorage.removeItem('signature');
          router.push({
            path: '/splashScreen',
          });
        });
      };

      const refreshUserInfo = async () => {
        const userInfo = await Web3Provider.getInstance().getUserInfo();
        vipInfo.level = vipLevel[userInfo.eUserLevel]['level'];
        vipInfo.icon = vipLevel[userInfo.eUserLevel]['icon'];
      };
      onMounted(async () => {
        handleGlobalListener();
        refreshUserInfo();
        let address = await Web3Provider.getInstance().getAccountAddress();
        if (address && address.length === 42) {
          userAddress.value = address.substring(0, 5) + '...' + address.substring(38, 42);
        }
      });

      return {
        state,
        vipInfo,
        userAddress,
        refreshUserInfo,
        handleGlobalListener,
      };
    },
  });
</script>

<style scoped>
  @media screen and (min-width: 426px) {
    .layout-fixed-width {
      max-width: 426px;
      padding: 0px calc((100% - 426px) / 2);
      height: 100%;
    }
  }

  @media screen and (max-width: 426px) {
    .layout-fixed-width {
      width: 100%;
      height: 100%;
    }
  }

  .content-section {
    min-height: calc(100vh - 130px);
  }
</style>
