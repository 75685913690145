import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34b91264"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item")!
  const _component_van_tabbar = _resolveComponent("van-tabbar")!
  const _component_van_sticky = _resolveComponent("van-sticky")!

  return (_openBlock(), _createBlock(_component_van_sticky, { position: "bottom" }, {
    default: _withCtx(() => [
      _createVNode(_component_van_tabbar, {
        modelValue: _ctx.active,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
        "active-color": "#CD2A16",
        "inactive-color": "#575962"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabBarList, (item) => {
            return (_openBlock(), _createBlock(_component_van_tabbar_item, {
              key: item.title,
              to: item.path
            }, {
              icon: _withCtx((props) => [
                _createElementVNode("img", {
                  src: props.active ? item.active : item.inactive
                }, null, 8, _hoisted_1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}