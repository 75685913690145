import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0080bb07"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"font-size":"17px","font-weight":"600"} }
const _hoisted_2 = { style: {"margin-right":"26px","margin-left":"8px"} }
const _hoisted_3 = { style: {"margin-left":"8px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createBlock(_component_van_nav_bar, {
    border: false,
    fixed: ""
  }, {
    left: _withCtx(() => [
      _createVNode(_component_svg_icon, {
        "icon-class": "left-arrow",
        style: {"width":"24px","height":"25px","margin-right":"9px"},
        onClick: _ctx.handleGoBack
      }, null, 8, ["onClick"]),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    right: _withCtx(() => [
      _createVNode(_component_svg_icon, {
        "icon-class": _ctx.vip_level ? _ctx.vip_level.icon : _ctx.vipLevel['0'].icon,
        style: {"width":"24px","height":"24px"}
      }, null, 8, ["icon-class"]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.vip_level ? _ctx.vip_level.level : _ctx.vipLevel['0'].level), 1),
      _createVNode(_component_svg_icon, {
        "icon-class": "small-wallet",
        style: {"width":"24px","height":"24px"}
      }),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.adress), 1)
    ]),
    _: 1
  }))
}